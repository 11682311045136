import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import SiteLayout from "./SiteLayout"
import PageSEO from "./PageSEO"

export default function AboutPageTemplate({ data }) {
  const page = data.markdownRemark
  return (
    <SiteLayout>
      <PageSEO title={page.frontmatter.title} />
      <h2>{page.frontmatter.title}</h2>
      <div dangerouslySetInnerHTML={{ __html: page.html }} />
      <StaticImage
        src="../images/Michael_Owens_Quartet.jpeg"
        width={550}
        quality={90}
      />
    </SiteLayout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`
